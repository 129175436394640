<template>
  <div class="about">
    <h3>O nama</h3>
    <p>Ovaj projekat pretstavlja primer kako se pravi web aplikacija u naprednom javascript okruženju poput Vue 3</p>
    <p>Za stilizovanje korišćen je Bootstrap 5 dok za dopremanje podataka korišćena je biblioteka Axios</p>
    <p>Podaci u ovoj aplikaciji su u realnom vremenu</p>
    <br>
    <h3>Korisni linkovi</h3>
    <ul>
      <li><a href="https://vuejs.org/guide/introduction.html" target="_blank">Vue 3 dokumentacija</a></li>
      <li><a href="https://getbootstrap.com/docs/5.0/getting-started/introduction/" target="_blank">Bootstrap 5 dokumentacija</a></li>
      <li><a href="https://github.com/Pequla/flight-cache#endpoints" target="_blank">Lista putanja bekenda za letove</a></li>
      <li><a href="https://img.pequla.com/destination/" target="_blank">Repozitorijum slika</a></li>
      <li><a href="https://github.com/Pequla/primer-vue" target="_blank">Izvorni kod aplikacije</a></li>
    </ul>
  </div>
</template>
